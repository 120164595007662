import React from 'react';
import { useTranslation } from 'src/i18n';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton
import { getSectionLinkGenFn } from 'utils/search';
import usePageContext from 'utils/hooks/usePageContext';
import { TP } from 'constants/index';
import { search_types } from 'constants/search';
import styles from './NoResultsComponent.module.scss';

const NoResultsComponent = ({ query, isSmall = false, isFiltered, filterLabel, otherResultsCount }) => {
  const { t } = useTranslation(['NS_GLOBAL_SEARCH', 'NS_APP_GLOBALS']);
  const { navigate } = usePageContext();
  return (
    <>
      <div className={classnames(styles.root, { [styles.smallStyle]: isSmall })}>
        <div className={styles.iconContainer}>
          <SpriteIcon icon="not_found" size={isSmall ? 42 : 80} className={styles.searchIcon} />
        </div>
        <div className={styles.subTitle}>
          <Typography weight="bold" size={isSmall ? 14 : 20}>
            {t(isFiltered ? `${TP}.SRCH_NO_RESULTS_FOR` : `${TP}.SRCH_NO_RESULTS`, {
              query,
              type: t(`NS_APP_GLOBALS:${filterLabel}`),
            })}
          </Typography>
        </div>
        <div>
          <Typography color="secondary" size={isSmall ? 12 : 16}>
            {t(`${TP}.SRCH_TRY_ADJUST`)}
          </Typography>
        </div>
      </div>
      {otherResultsCount > 0 && isSmall && (
        <Link
          {...navigate.getLinkProps(getSectionLinkGenFn(search_types.ALL)(query))}
          className={styles.showOtherResults} disableUnderline
        >
          <Typography color="link" size={16} weight="medium">
            {t(`${TP}.SRCH_SEE_ALL_OTHER_RESULTS`, { query, count: otherResultsCount })}
          </Typography>
        </Link>
      )}
    </>
  );
};

export default NoResultsComponent;
